import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  FaTelegram,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

// Footer Component
const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6 md:py-8 mt-8">
      <div className="container mx-auto flex flex-col items-center px-4">
        <div className="flex flex-col items-center mb-6">
          <a
            href="https://www.gebetas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/Gebeta.png" alt="Gebeta Logo" className="w-24 mb-2" />
          </a>
          <p className="text-xl md:text-2xl font-dancing">
            "Lettuce Take a Byte!"
          </p>
        </div>
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2 font-bree text-center">
            Contact Us
          </h3>
          <p className="font-salsa text-center">
            Email:{" "}
            <a href="mailto:mail@gebeta.com" className="text-green-400">
              mail@gebetas.com
            </a>
          </p>
          <p className="font-salsa text-center">
            Phone:{" "}
            <a href="tel:+1234567890" className="text-green-400">
              (+251) 900296860
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2 font-bree text-center">
            Follow Us
          </h3>
          <div className="flex space-x-4 justify-center">
            <a
              href="https://t.me/Gebeta_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTelegram className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://facebook.com/Gebeta Menu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://twitter.com/Gebeta_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://instagram.com/Gebeta_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://youtube.com/@Gebeta_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://linkedin.com/in/BenjaminMenedo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="text-2xl hover:text-green-400" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center mb-3 md:mb-0">
          <p className="font-salsa text-center">
            <a
              href="/PrivacyPolicy" // Ensure this path matches your routing
              className="text-green-400 hover:underline"
            >
              PRIVACY POLICY
            </a>
          </p>
        </div>{" "}
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <p className="font-salsa text-center">
            <a
              href="/Terms" // Ensure this path matches your routing
              className="text-green-400 hover:underline"
            >
              TERMS OF SERVICE
            </a>
          </p>
        </div>
      </div>
      <div className="text-center mt-6 md:mt-8 font-salsa">
        <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
          &copy; 2024 Gebeta. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

// AdminDashboard Component
const Admin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [editedItem, setEditedItem] = useState({
    id: null,
    price: "",
    stock: true,
  });
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isLoggedIn) {
      const restaurantId = localStorage.getItem("restaurant_id");
      fetch(`https://gebetas.com/api.php?restaurant_id=${restaurantId}`)
        .then((response) => response.json())
        .then((data) => setMenuItems(data))
        .catch((error) => console.error("Error fetching menu items:", error));
    }
  }, [isLoggedIn]);

  const CODE = "Classic";

  const handleLogin = () => {
    const restaurantId = localStorage.getItem("restaurant_id");
    axios
      .post("https://gebetas.com/auth.php", {
        action: "login",
        username,
        password,
        restaurant_id: restaurantId,
        code: CODE,
      })
      .then((response) => {
        if (response.data.success) {
          setIsLoggedIn(true);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("restaurant_id", response.data.restaurant_id);
        } else {
          alert("Login failed: " + response.data.message);
        }
      })
      .catch((error) => console.error("Error logging in:", error));
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    if (loggedIn) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername("");
    setPassword("");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("restaurant_id");
  };

  const handlePasswordUpdate = () => {
    axios
      .post("https://gebetas.com/auth.php", {
        action: "update_password",
        username,
        new_password: newPassword,
      })
      .then((response) => {
        alert(response.data.message);
        setNewPassword("");
        setShowPasswordPopup(false);
      })
      .catch((error) => console.error("Error updating password:", error));
  };

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedItem((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleEditClick = (item) => {
    setEditedItem({
      id: item.id,
      price: item.price,
      stock: item.stock,
    });
  };

  const handleUpdate = () => {
    const formData = new FormData();
    formData.append("id", editedItem.id);
    formData.append("price", editedItem.price);
    formData.append("stock", editedItem.stock);
    fetch("https://gebetas.com/api.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(data.message);
          setMenuItems((prevItems) =>
            prevItems.map((item) =>
              item.id === editedItem.id
                ? { ...item, price: editedItem.price, stock: editedItem.stock }
                : item
            )
          );
        } else {
          alert("Error updating the item.");
        }
        setEditedItem({ id: null, price: "", stock: true });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <header className="mt-4 mx-4 mb-4 p-4 shadow-2xl bg-gradient-to-r from-indigo-700 via-purple-300 to-pink-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <div className="flex-grow flex justify-center">
            <img
              src="/Gebeta.png"
              alt="Logo"
              className="w-24 h-auto object-contain"
            />
          </div>
          {isLoggedIn && (
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="text-white focus:outline-none"
              >
                {/* Hamburger Menu Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
              {showDropdown && (
                <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-md z-50">
                  <button
                    onClick={() => {
                      setShowPasswordPopup(true);
                      setShowDropdown(false);
                    }}
                    className="block px-4 py-2 font-bree text-left w-full hover:bg-gray-200"
                  >
                    Change Password
                  </button>
                  <button
                    onClick={() => {
                      handleLogout();
                      setShowDropdown(false);
                    }}
                    className="block px-4 py-2 font-bree text-left w-full hover:bg-gray-200"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </header>

      {!isLoggedIn ? (
        <div className="flex-grow flex items-center justify-center my-8 mx-4">
          <div className="bg-white bg-opacity-30 backdrop-blur-md mb-48 mt-24 shadow-lg rounded-lg p-6 max-w-sm w-full">
            <h2 className="text-2xl sm:text-2xl lg:text-3xl font-semibold mb-6 text-center text-gray-600 font-dancing">
              Admin Login
            </h2>
            <input
              id="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleLogin()} // Allow Enter key to submit
              autoComplete="off" // Prevents saving the username
              className="border border-gray-300 font-bree p-4 mb-4 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300 ease-in-out"
            />
            <input
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleLogin()} // Allow Enter key to submit
              autoComplete="off" // Prevents saving the password
              className="border border-gray-300 font-bree p-4 mb-6 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300 ease-in-out"
            />
            <button
              onClick={handleLogin}
              className="bg-gradient-to-r from-cyan-700 to-pink-700 font-bree text-white px-6 py-3 rounded-md w-full hover:bg-opacity-80 transition duration-300 ease-in-out shadow-md transform hover:scale-105"
            >
              Login
            </button>
          </div>
        </div>
      ) : (
        <div className="flex-grow mx-4">
          <div className="max-w-7xl mx-auto mt-8">
            <table className="w-full table-auto bg-white shadow-lg rounded">
              <thead>
                <tr className="bg-gradient-to-r from-indigo-700 via-purple-600 to-pink-600 text-white">
                  <th className="py-2 px-4 font-raleway">Name</th>
                  <th className="py-2 px-4 font-raleway">Price</th>
                  <th className="py-2 px-4 font-raleway">In Stock</th>
                  <th className="py-2 px-4 font-raleway">Actions</th>
                </tr>
              </thead>
              <tbody>
                {menuItems.length ? (
                  menuItems.map((item) => (
                    <tr key={item.id} className="text-center border-b">
                      <td className="py-2 px-4 font-bree">{item.name}</td>
                      <td className="py-2 px-4 font-bree">
                        {editedItem.id === item.id ? (
                          <input
                            type="number"
                            name="price"
                            value={editedItem.price}
                            onChange={handleEditChange}
                            className="border p-2 w-full "
                          />
                        ) : (
                          item.price
                        )}
                      </td>
                      <td className="py-2 px-4 text-xl font-bree">
                        {editedItem.id === item.id ? (
                          <input
                            type="checkbox"
                            name="stock"
                            checked={editedItem.stock}
                            onChange={handleEditChange}
                          />
                        ) : item.stock ? (
                          <span className="text-green-500">Yes</span>
                        ) : (
                          <span className="text-red-500">No</span>
                        )}
                      </td>
                      <td className="py-2 px-4 font-bree">
                        {editedItem.id === item.id ? (
                          <button
                            onClick={handleUpdate}
                            className="bg-green-500 text-white px-4 py-2 rounded"
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            onClick={() => handleEditClick(item)}
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                          >
                            Edit
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="text-center py-4 text-gray-500 font-bree"
                    >
                      No menu items available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Password Change Popup */}
      {showPasswordPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-bold mb-4 font-raleway">
              Change Password
            </h2>
            <input
              id="change_password"
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="border p-2 w-full mb-4 font-bree"
            />
            <div className="flex justify-end">
              <button
                onClick={handlePasswordUpdate}
                className="bg-blue-500 text-white px-4 py-2 font-bree rounded mr-2"
              >
                Update
              </button>
              <button
                onClick={() => setShowPasswordPopup(false)}
                className="bg-gray-300 text-black px-4 font-bree py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Admin;
