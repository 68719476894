import React from "react";
import {
  FaCalendarAlt,
  FaInfoCircle,
  FaLock,
  FaCookieBite,
  FaUserSecret,
  FaShieldAlt,
  FaSyncAlt,
  FaEnvelope,
  FaPhone,
  FaInstagram
} from "react-icons/fa";

const Terms = () => {
  return (
    <div className="bg-gray-100 py-16 px-6 sm:px-12 md:px-20 lg:px-32">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8 md:p-12">
        <h1 className="text-2xl md:text-3xl font-bold font-bree text-blue-600 text-center mb-8">
          Terms of Service for Gebeta
        </h1>

        <p className="text-gray-600 mb-8 text-sm flex items-center">
          <FaCalendarAlt className="text-blue-500 mr-2" />
          <strong>Effective Date: </strong> November 5, 2024
        </p>

        <section className="mb-6">
          <p className="text-gray-700">
            Welcome to Gebeta! These Terms of Service ("Terms") govern your
            access to and use of our services, including our digital menu system
            ("Service"). By using our Service, you agree to these Terms. If you
            do not agree, do not use our Service.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaInfoCircle className="text-blue-500 mr-2" /> Acceptance of Terms
          </h2>
          <p className="text-gray-700">
            By accessing or using Gebeta, you agree to be bound by these Terms
            and our Privacy Policy. If you are using the Service on behalf of an
            organization, you represent that you have the authority to bind that
            organization to these Terms.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaInfoCircle className="text-blue-500 mr-2" /> Description of
            Service
          </h2>
          <p className="text-gray-700">
            Gebeta provides a digital menu system for restaurants, hotels, and
            cafes. Our Service allows waitstaff to create, manage, and display
            digital menus and related content without the need for user accounts
            or data collection.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaLock className="text-blue-500 mr-2" /> Use of the Service
          </h2>
          <p className="text-gray-700">
            By using Gebeta, you agree to comply with applicable laws and these
            Terms. You may not use the Service for illegal or unauthorized
            purposes, including violating intellectual property rights or
            distributing harmful software. Gebeta reserves the right to suspend
            or terminate your access to the Service if you violate these Terms
            or engage in any prohibited activities.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaCookieBite className="text-blue-500 mr-2" /> Payment Terms
          </h2>
          <p className="text-gray-700">
            If you purchase a subscription or any other paid services, you agree
            to pay all fees associated with your account. All payments are
            non-refundable except as required by law.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaUserSecret className="text-blue-500 mr-2" /> Intellectual
            Property
          </h2>
          <p className="text-gray-700">
            All content, trademarks, and other intellectual property associated
            with the Service are owned by Gebeta or its licensors. You may not
            use any of our intellectual property without our prior written
            consent.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaShieldAlt className="text-blue-500 mr-2" /> Data Privacy
          </h2>
          <p className="text-gray-700">
            Gebeta does not collect personal data from users. Please review our
            Privacy Policy for details on how we handle any information that may
            be collected during your interaction with our Service.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaUserSecret className="text-blue-500 mr-2" /> Third-Party Services
          </h2>
          <p className="text-gray-700">
            Gebeta may integrate or rely on third-party services (such as
            payment processors) to provide certain features. By using these
            services, you agree to their terms and conditions. Gebeta is not
            liable for any issues arising from your use of third-party services.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaSyncAlt className="text-blue-500 mr-2" /> Service Availability
          </h2>
          <p className="text-gray-700">
            Gebeta strives to provide uninterrupted access to the Service but
            does not guarantee that the Service will be available at all times.
            Scheduled maintenance, updates, or unforeseen issues may result in
            temporary downtime. Gebeta reserves the right to modify or
            discontinue any part of the Service at any time.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaLock className="text-blue-500 mr-2" /> Disclaimer of Warranties
          </h2>
          <p className="text-gray-700">
            The Service is provided "as is" and "as available" without
            warranties of any kind, either express or implied. Gebeta disclaims
            all warranties, including any implied warranties of merchantability,
            fitness for a particular purpose, and non-infringement.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaShieldAlt className="text-blue-500 mr-2" /> Limitation of
            Liability
          </h2>
          <p className="text-gray-700">
            To the fullest extent permitted by law, Gebeta and its affiliates
            are not liable for any indirect, incidental, special, consequential,
            or punitive damages arising out of or relating to your use of or
            inability to use the Service.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaUserSecret className="text-blue-500 mr-2" /> Indemnification
          </h2>
          <p className="text-gray-700">
            You agree to indemnify, defend, and hold Gebeta harmless from any
            claims, damages, liabilities, and expenses (including legal fees)
            arising out of or related to your use of the Service, violation of
            these Terms, or infringement of any third-party rights.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaSyncAlt className="text-blue-500 mr-2" /> Termination
          </h2>
          <p className="text-gray-700">
            Gebeta reserves the right to suspend or terminate your access to the
            Service at any time, with or without cause or notice. You may stop
            using the Service at any time.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-3 flex items-center">
            <FaEnvelope className="text-blue-500 mr-2" /> Contact Information
          </h2>
          <p className="text-gray-700">
            If you have any questions or concerns regarding these Terms, please
            contact us at:
          </p>
          <div className="mt-4 pl-4">
            <p className="text-gray-700">
              <strong>Gebeta Digital Menu Service</strong>
            </p>
            <p className="text-gray-700 flex items-center">
              <FaEnvelope className="text-blue-500 mr-2" /> Email:
              mail@gebeta.com
            </p>
            <p className="text-gray-700 flex items-center">
              <FaPhone className="text-blue-500 mr-2" /> Phone: (+251) 900296860
            </p>
            <p className="text-gray-700 flex items-center">
              <FaInstagram className="text-blue-500 mr-2" /> Social Media:
              @gebeta_official
            </p>
          </div>
        </section>

        <p className="text-gray-600 text-sm text-center">
          By using the Gebeta Service, you agree to the terms of this Terms of
          Service...
        </p>
      </div>
    </div>
  );
};

export default Terms;
